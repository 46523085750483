import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import Seo from '../components/seo';
import { Container, Row, Col } from 'react-grid-system';

import Hero from '../components/rows/hero/index';
import Banner from '../images/hero-images/faq-hero.jpg';
// import TwoCol from '../components/rows/twocol/index'
import Follow from '../components/rows/cta/follow';
import Accordion from '../components/elements/accordion';

export default function PatientFAQ({ data }) {
  return (
    <Layout>
      <Seo
        title="Patient FAQ"
        description="We’ve got answers to your questions about PACE Cardiology, including how to book your appointment & what 
          to expect when you visit us. Check out our FAQs here"
      />
      <Hero title="Patient FAQ" banner={Banner} />
      {data.allStrapiPatientFaq.edges.map(({ node }) => (
        <React.Fragment key={node}>
          <Container className="acc-wrap mb-56">
            <Row>
              <Col md={6} sm={12} className="mx-auto">
                <h2 className="text-center">
                  Health <span className="text-red-300">info</span> &amp;{' '}
                  <span className="text-red-300">tips</span>
                </h2>
              </Col>
            </Row>
            <Accordion data={node.faq_acc} />
          </Container>
        </React.Fragment>
      ))}
      <Follow />
    </Layout>
  );
}

export const query = graphql`
  query FAQ {
    allStrapiPatientFaq {
      edges {
        node {
          id # id of the node
          faq_acc {
            id
            title
            description
          }
        }
      }
    }
  }
`;
